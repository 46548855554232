/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {McProductFilter} from '../models/mc-product-filter.model';

export class McProductFilterGENERATED extends BaseObjectFilter{    public searchTerm: string | undefined;
    public state: string | undefined;
    public category: string | undefined;
    public dateFrom: any | undefined;
    public dateTo: any | undefined;
    public idMcEntity: number | undefined;
    public pricingType: string | undefined;

  public properties: string[] = ['searchTerm', 'state', 'category', 'dateFrom', 'dateTo', 'idMcEntity', 'pricingType']
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McProductFilter {
    console.error('MTCN-ERROR: Not Implemented: McProductFilter::createNewInstance(). Add this method to final class and return new McProductFilter();');
      throw new Error('Cannot McProductFilter::createNewInstance(). Add this method to final class and return new McProductFilter();');
  }

}
