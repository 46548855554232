import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpParams} from '@angular/common/http';
import {McAuthService, LoginEnums, McUtilityService, McGod} from '@miticon-ui/mc-core';


@Component({
  selector: 'lib-login',
  templateUrl: './login.component.html',
  styleUrls: [ '../../style/mc-login.scss' ],
})
export class LoginComponent implements OnInit {

  welcomeImg: string;
  logo: string;
  entityId!: number;
  isFormValid!: boolean;
  seePassword!: boolean;
  inputFieldsError!: boolean;
  responseMessage!: boolean;
  message!: string;
  type!: string;
  isKeepMeLoggedInChecked!: boolean;
  userLoggedIn!: boolean | undefined;
  receivedState!: any;
  showLoader!: boolean;

  loginForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: McAuthService,
    private utilityService: McUtilityService,
    @Inject('designConfig') design: any,
  ) {
    this.loginForm = new FormGroup({});
    this.welcomeImg = design.welcomeImg;
    this.logo = design.logo;

    this.activatedRoute.queryParams.subscribe(() => {
      if (this.router.getCurrentNavigation() && this.router.getCurrentNavigation()?.extras.state) {
        this.receivedState = this.router.getCurrentNavigation()?.extras?.state?.['data'];
      }
    });
  }

  ngOnInit() {
    this.isKeepMeLoggedInChecked = true;
    this.createForm();
    this.validateLogin();

    /* Keep me login logic*/
    this.userLoggedIn = this.checkLoginCredentials();
    if (this.userLoggedIn) {
      this.router.navigate([`admin/entity/${this.entityId}/dashboard`]);
      return;
    } else {
      this.authService.logout();
    }

    this.loginForm.valueChanges.subscribe(() => {
      this.validateLogin();
    });

    localStorage.removeItem("mc-select-entity-modal-opened");
  }

  /* Keep me login logic*/
  checkLoginCredentials(): boolean | undefined {
    if (this.receivedState) {
      localStorage.removeItem('mc-access-token');
    }
    let authToken: any;
    // @ts-ignore
    authToken = JSON.parse(localStorage ? localStorage.getItem('mc-access-token') : undefined);
    // @ts-ignore
    const refreshToken = JSON.parse(localStorage.getItem('mc-refresh-token'));
    const now = new Date().valueOf();

    if (authToken) {
      this.entityId = this.utilityService.getPropertyFromToken('entity_id');
      const tokenExpireTime = this.utilityService.getPropertyFromToken('exp');
      if (!tokenExpireTime || now >= (tokenExpireTime * 1000)) {
        localStorage.removeItem('mc-access-token');
        if (refreshToken) {
          this.entityId = this.utilityService.getPropertyFromRefreshToken('entity_id');
          const refreshTokenExpireTime = this.utilityService.getPropertyFromRefreshToken('exp');
          if (!refreshTokenExpireTime || now >= (refreshTokenExpireTime * 1000)) {
            this.authService.logout();
          } else {
            return true;
          }
        }
      } else {
        return true;
      }
    } else if (refreshToken) {
      this.entityId = this.utilityService.getPropertyFromRefreshToken('entity_id');
      const refreshTokenExpireTime = this.utilityService.getPropertyFromRefreshToken('exp');
      if (!refreshTokenExpireTime || now >= (refreshTokenExpireTime * 1000)) {
        this.authService.logout();
      } else {
        return true;
      }
    } else {
      return false;
    }
    return undefined;
  }

  /* Keep me login logic# */

  // Initialize form
  createForm() {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      remember_me: this.isKeepMeLoggedInChecked
    });
  }

  // See password on eye-button click
  onChangeSeePassword(seePassData: boolean) {
    this.seePassword = seePassData;
  }

  // Keep Me logged in checkbox
  rememberMeChb(e: Event) {
    this.isKeepMeLoggedInChecked = (e.target as HTMLInputElement).checked;
    this.loginForm.patchValue({
      remember_me: this.isKeepMeLoggedInChecked
    });
  }

  // Check and set if form valid
  validateLogin() {
    this.inputFieldsError = false;
    this.responseMessage = false;
    const formData = this.loginForm.getRawValue();
    this.isFormValid = !(formData.username === null || formData.password === null);
  }

  // Login
  login() {
    this.showLoader = true;
    let msgText: string;
    let msgType: string;
    const formData = this.loginForm.getRawValue();
    const body = new HttpParams()
      .set('username', formData.username)
      .set('password', formData.password)
      .set('remember_me', formData.remember_me)
      .set('grant_type', 'password');
    this.authService.initLogin(body).subscribe(
      (response) => {
        if (response.access_token) {
          /*Store user data into storage*/
          this.utilityService.storeAuthData(response);
          this.entityId = response.entity_id;
          msgText = LoginEnums.successMsg;
          msgType = LoginEnums.successMsg;
          this.setNotificationMessage(msgText, msgType);
          McGod.getInstance().fireBroadcastTriggerLoginChanged();
          if (response.pia !== undefined) {
            this.router.navigate([`auth/incomplete-profile`], {
              state: {
                entity_id: response.entity_id
              }
            });
          } else {
            this.router.navigate([`admin/entity/${this.entityId}/dashboard`]);
          }
        } else if (response.error === LoginEnums.mfaRequired) {
          msgText = LoginEnums.successMsg;
          msgType = LoginEnums.successMsg;
          this.responseMessage = true;
          this.setNotificationMessage(msgText, msgType);
          localStorage.setItem('mc-access-token', JSON.stringify(response.mfa_token));
          this.router.navigate(['auth/two-fa-login'], {
            state: {
              mfa_recovery_values: response.mfa_recovery_values,
              mfa_token: response.mfa_token,
              mfa_uri: response.mfa_uri
            }
          });
        }
        this.showLoader = false;
      },
      (error) => {
        if (error.status >= 500) {
          msgText = 'Please try again in a few minutes.';
          msgType = 'error';
          this.responseMessage = true;
          this.setNotificationMessage(msgText, msgType);
          this.showLoader = false;
          return;
        } else if (error.status > 400 && error.status < 500) {
          this.loginForm.get("username")?.setValue("");
          this.loginForm.get("password")?.setValue("");
          msgText = "Entity is not connected with this user.";
          msgType = error.ok === false ? LoginEnums.errorMsg : LoginEnums.successMsg;
          this.inputFieldsError = true;
          this.responseMessage = true;
          this.setNotificationMessage(msgText, msgType);
        } else if (error.status === 400) {
          console.log(error);
          this.loginForm.get("username")?.setValue("");
          this.loginForm.get("password")?.setValue("");
          this.inputFieldsError = true;
          this.responseMessage = true;
          msgText = error.error.error_description || error.error.message;
          msgType = error.ok === false ? LoginEnums.errorMsg : LoginEnums.successMsg;
          this.setNotificationMessage(msgText, msgType);
        }
        if (error.hasOwnProperty('error') && error.error.error === LoginEnums.retryLoginAttempt) {
          this.router.navigate(['auth/auth-locked'], {
            state: {
              error: error.error,
            }
          });
        }
        this.showLoader = false;
      }
    );
  }

  // Display notification message
  private setNotificationMessage(message: string, type: string): void {
    this.message = message;
    this.type = type;
  }

}
