/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
/* Using "any" data-type in returned observables to avoid importing   */
/* ItemClass and ItemListClass and circular dependencies.             */
/* ------------------------------------------------------------------ */
import {BaseObjectService} from '../_core/base-object-service';
import {IEbConsumerBlacklist} from '../_generated/eb-consumer-blacklist.interface';
import {SortCriteriaList} from '../_core/sort-criteria-list.model';
// import {EbConsumerBlacklist} from '../models/eb-consumer-blacklist.model';
// import {EbConsumerBlacklistList} from '../models/eb-consumer-blacklist-list.model';
// import {EbConsumerBlacklistFilter} from '../models/eb-consumer-blacklist-filter.model';
import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { map } from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EbConsumerBlacklistServiceGENERATED extends BaseObjectService {
protected apiServiceUrlSubPath = "/ebbank/v3/ebconsumerblacklist";

public getApiServiceRootUrl(): string {
    return this.apiUrl + this.apiServiceUrlSubPath;
  }

  /* Get all  from DB */
public getAll(aPageNo: number, aItemsPerPage: number, sortCriteria: SortCriteriaList): Observable<any> {
    return this.httpClient.get<any>(this.getApiServiceRootUrl() + `/all?page=${aPageNo}&size=${aItemsPerPage}${sortCriteria.toUrlQueryString()}`, {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}

  /* Get all  from DB without pager */
public getByFilter(aFilter: any, aPageNo: number, aItemsPerPage: number, sortCriteria: SortCriteriaList): Observable<any> {
    return this.httpClient.post<any>(this.getApiServiceRootUrl() + `/filter?page=${aPageNo}&size=${aItemsPerPage}${sortCriteria.toUrlQueryString()}`, aFilter.toDto(), {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}

/* Get by id from DB */
public getById(id: number): Observable<IEbConsumerBlacklist> {
  return this.httpClient.get<IEbConsumerBlacklist>(this.getApiServiceRootUrl() + `/${id}`, {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}

// Insert Object
public insert(aEbConsumerBlacklist: any): Observable<IEbConsumerBlacklist> {
    return this.httpClient.post<IEbConsumerBlacklist>(this.getApiServiceRootUrl()  , aEbConsumerBlacklist.toDto(), {headers: this.httpOptions});
}
// Update object
public update(aEbConsumerBlacklist: any): Observable<IEbConsumerBlacklist> {
    return this.httpClient.put<IEbConsumerBlacklist>(this.getApiServiceRootUrl() + `/`+ aEbConsumerBlacklist.id, aEbConsumerBlacklist.toDto(), {headers: this.httpOptions});
}


// DeleteAllFromDB
public deleteAllFromDb() {
    return this.httpClient.delete<any>(this.getApiServiceRootUrl() + `/deleteAllFromDb`, {headers: this.httpOptions});
  }

  /* Get by id from DB */
public deleteById(id: number) {
    return this.httpClient.delete<any>(this.getApiServiceRootUrl() + `/${id}`, {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
        })
    });

  }

  // -- CUSTOM METHODS --

  // ---------------------------------------------------------------------
  public getActiveByIdCsrConsumer( idCsrConsumer: number): Observable<IEbConsumerBlacklist> {
    return this.httpClient.get<IEbConsumerBlacklist>(this.getApiServiceRootUrl()
      + `/getactivebyidcsrconsumer/${idCsrConsumer}`, {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}

  // ---------------------------------------------------------------------
  public addConsumersToBlacklist( consumerIds: any, reason: string) {
    const body: any = {};
    body.consumerIds = consumerIds;
body.reason = reason;

    return this.httpClient.post<any>(this.getApiServiceRootUrl() + `/addconsumerstoblacklist`,  body,  {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}

  // ---------------------------------------------------------------------
  public removeConsumersFromBlacklist( consumerIds: any, reason: string) {
    const body: any = {};
    body.consumerIds = consumerIds;
body.reason = reason;

    return this.httpClient.post<any>(this.getApiServiceRootUrl() + `/removeconsumersfromblacklist`,  body,  {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      })
  });

}

}
