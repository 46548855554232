<section class="mc-create-invoice-modal mb-5 mc-invoice-section" [ngStyle]="handleCssForWrapper()">

  <div class="row mx-0">
    <div class="mc-go-back-to-all" (click)="goToPrevious()">
      < {{'cc.common.back-to-previous-page' | translate}}
    </div>
  </div>

  <div class="modal-header mc-create-user-modal-header">
    <div>
      <h4 class="modal-title" id="mc-modal-new-user-title"> {{'cc.consumers.view.invoice-for' | translate}} {{invoiceReceiver}}</h4>
    </div>
  </div>

  <div class="modal-body">
    <div class="mc-create-new-user-wrapper">
      <div class="container-fluid p-0">
        <form [formGroup]="mainForm" class="mc-invoice-product-form">

<!--          <div class="mc-create-user-notify-wrapper">-->
<!--            <mc-notify-message *ngIf="responseMessage" [message]="message" [type]="type"></mc-notify-message>-->
<!--          </div>-->

          <lib-mc-stepper [isDataFormInvalid]="isDataFormInvalid">
            <!-- Products -->
            <cdk-step>
              <lib-invoice-step1 [parentForm]="mainForm"
                                 (isDataFormInvalid)="userDataFormInvalid($event)"
                                 (selectedTableProducts)="selectedTableProducts($event)">
             </lib-invoice-step1>
            </cdk-step>
            <cdk-step>
              <lib-invoice-step-payment [parentForm]="mainForm"
                                        [csrConsumerId]="userId"
                                        (isDataFormInvalid)="userDataFormInvalid($event)"
                                        (paymentDetails)="setPaymentDetails($event)">
              </lib-invoice-step-payment>
            </cdk-step>
            <!-- Schedule & Summary-->
            <cdk-step>
              <lib-invoice-step2 [parentForm]="mainForm"
                                 (isDataFormInvalid)="userDataFormInvalid($event)"
                                 [csrPaymentDetails]="dataFromStep2">
              </lib-invoice-step2>
            </cdk-step>
          </lib-mc-stepper>
        </form>
      </div>
    </div>
  </div>

</section>

<!--Loader-->
<lib-mc-loader [showLoader]="showLoader"></lib-mc-loader>

