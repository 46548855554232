import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {McAuthService, McUtilityService, LoginEnums, VerifyTwoFaRequest} from '@miticon-ui/mc-core';

@Component({
  selector: 'app-two-fa-login',
  templateUrl: './two-fa-login.component.html',
  styleUrls: [`../../style/mc-login.scss`]
})
export class TwoFaLoginComponent implements OnInit {
  welcomeImg: string;
  logo: string;
  qrCode!: string;
  message!: string;
  type!: string;
  twoFaResponse!: any;
  mobileIcons;

  /*Constructor*/
  constructor(private router: Router,
              private route: ActivatedRoute,
              private authService: McAuthService,
              private utilityService: McUtilityService,
              @Inject('designConfig') design: any) {

    this.welcomeImg = design.welcomeImg;
    this.logo = design.logo;
    this.mobileIcons = design.mobileIcons;

    /*Get state from login componen`t*/
    /*if state no exists redirect user to login page*/
    this.route.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation() && this.router.getCurrentNavigation()?.extras.state) {
        this.twoFaResponse = this.router.getCurrentNavigation()?.extras.state;
      } else {
        this.authService.logout();
      }
    });
  }

  /*On init component*/
  ngOnInit() {
  }

  /*Go to second step two fa login*/
  public goToNextStepTwoFA(): void {
    // Check if QR code exists
    if (!this.qrCode) {
      this.setErrorMessage('Please enter the code provided from the application', 'error');
      return;
    }

    /*API body*/
    const body: VerifyTwoFaRequest = {
      mfa_otp: this.qrCode,
      mfa_token: this.twoFaResponse.mfa_token,
      grant_type: 'mfa_otp'
    };

    /*Call login service*/
    this.authService.verify2fa(body)
      .subscribe(response => {
        /*Check if select entity is required*/
        if (response.hasOwnProperty('error')) {
          this.setErrorMessage('Successfully logged in. /Redirect to Select Entity page/', 'success');
        } else {
          const userId = response.user_id;

          /*Store user data*/
          this.utilityService.storeAuthData(response);

          /*Go to page*/
          if (response.pia !== undefined) {
            this.router.navigate([`auth/incomplete-profile`], {
              state: {
                entity_id: response.entity_id
              }
            });
          } else {
            this.router.navigate([`admin/entity/${userId}/dashboard`]);
          }
          this.setErrorMessage('Successfully logged in.', 'success');
        }
      }, (error) => {
        if (error.hasOwnProperty('error') && error.error.error === LoginEnums.retryLoginAttempt) {
          this.router.navigate(['auth/auth-locked'], {
            state: {
              error: error.error,
            }
          });
        }
        this.setErrorMessage(error.error.error_description, 'error');
      });
  }

  /*Go previous page - login*/
  public goToPreviousPage() {
    this.authService.logout();
  }

  /*Styling QR code input field on error*/
  public cssClassQrCodeInputField(): string {
    return this.type === 'error' ? 'mc-alert-error-input' : '';
  }

  /*Display error message*/
  private setErrorMessage(message: string, type: string): void {
    this.message = message;
    this.type = type;
    if (type === 'error') {
      this.qrCode = '';
    }
  }

  /*On press enter*/
  public onKeydown(event: any) {
    if (event.key === 'Enter') {
      this.goToNextStepTwoFA();
    }
  }

}
