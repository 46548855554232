<div class="row mc-row-no-margin">

  <div>
    <div class='mc-header-logo-wrapper'>
      <span class='mc-header-collapse-icon'
            (click)='collapseSidebar()'
            [innerHTML]='(isSidebarCollapsed ? iconExpande : iconCollapse) | safeHtml'>

      </span>
      <span class='mc-header-collapse-icon logo-icon' [innerHTML]='iconLogo | safeHtml'></span>

      <!--Select entity modal-->
      <div class="center-content">
        <div class="mc-header-path">
          <span *ngIf='selectedEntity'>{{selectedEntity.name}}</span>
          <button id="entityToggleBtn" class="mc-entity-toggle-btn" type="button">
            <mc-select-entity [selectedEntity]="selectedEntity" [openFromHeader]="true"
            ></mc-select-entity>
          </button>
        </div>
      </div><!--Select entity /end-->

      <!--User section-->
      <li class="mc-header-user-section">
        <img [src]="logo" class="img-fluid img-thumbnail rounded-circle" alt="user_img">
        <div (click)="actPersonalInfoClick()" id="userProfileDropdown" class="mc-header-user-section_name">  <!-- ngbDropdownToggle --> <!-- class="dropdown-toggle" -->
          {{username}}
        </div>
      </li><!--End user section-->
    </div>
  </div>
  <div class="col-md-10 mc-header-content">
    <div class="col-md-5 right-content">
      <ul class="mc-header-actions">

        <!--Software version-->
        <li class="mc-header-right-action mc-header-lang">
          <span>{{"cc.common.version" | translate}}</span> <span class="software-version">{{softwareVersion}}</span>
        </li>

        <!--Language dropdown-->
        <li (click)='panelOpenState = !panelOpenState' class="mc-header-right-action mc-header-lang">
          <div style='box-shadow: none' ngbDropdown>
            <div class="mc-dropdown-toggle text-uppercase" id="languageDropdown" ngbDropdownToggle>
              {{selectedLanguage}}
              <span [innerHTML]='(panelOpenState ? dropDownIcon : dropUpIcon) | safeHtml'></span>
            </div>
            <div ngbDropdownMenu class="mc-dropdown-menu mc-dropdown-language" aria-labelledby="languageDropdown">
              <a ngbDropdownItem
                 class="language-link"
                 *ngFor="let language of languagesList"
                 (click)="onChooseLanguage(language.abbreviation)"
              >{{language.name}}<span class="mc-dropdown-toggle-small text-uppercase">{{language.abbreviation}}</span></a>
            </div>
          </div>
        </li>

        <!--Help action-->
        <li class="mc-header-right-action mc-header-lang">
         <a class="help-link" target="_blank" href='https://www.my-factura.com/hilfe/'><i class="fa fa-question-circle"></i></a>
        </li>

        <li class="mc-header-right-action mc-header-logout">
          <a (click)="logout()" id="logout" placement="bottom">
            <span matTooltip="{{ 'cc.common.view.log-out' | translate}}"
                  [matTooltipClass]="'custom_tool_tip'"
                  [innerHTML]='iconLogout | safeHtml'>
            </span>
          </a>
        </li>
      </ul>
    </div>
  </div>

</div>



