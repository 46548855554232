<section class="mc-invoice-step justify-content-start">

  <div class="row mc-header-wrapper">
    <div class="col-md-12 mc-stepper-header-title">
      <span class="pl-4 pb-1 mr-2 mc-semi-active">
        <span class="mc-notification-badge-gray mc-semi-active-background">1</span>
        {{ 'cc.common.products' | translate }}
      </span>
      <span class="pl-4 pb-1 mr-2 mc-semi-active">
        <span class="mc-notification-badge-gray mc-semi-active-background">2</span>
        {{ 'cc.my-factoring.payment' | translate }}
      </span>
      <span class="pl-4 pb-1 ml-4 mr-2 mc-active">
        <span class="mc-notification-badge-gray mc-active-background">3</span>
        {{'cc.invoice.view.schedule-and-summary' | translate}}
      </span>
    </div>
  </div>

  <form class="form-row mc-add-consumers-form" [formGroup]="stepForm">

    <div class="mc-create-user-notify-wrapper mt-2">
      <mc-notify-message *ngIf="responseMessageDisplay" [message]="notifyMsgMessage" [type]="notifyMsgType"></mc-notify-message>
    </div>

    <div class="col-md-12">
      <h5 class="mc-schedule-title">{{'cc.common.view.schedule' | translate}}
        <span *ngIf="isEditArea" class="mc-invoice-status-{{receivedInvoiceDataStatus.toLowerCase()}}">{{receivedInvoiceDataStatus.toLowerCase()}}</span>
      </h5>

      <div class="row mx-0 mt-3 w-100">
        <div class="col-md-2 pt-2">
          <div class="form-group">
            <input
              id="saveAsDraft"
              class="form-check-input"
              type="radio"
              formControlName="scheduleState"
              value="DRAFT"
              (change)="changeSchedule($event)">
            <label class="form-check-label mc-invoice-schedule-select" for="saveAsDraft">{{'cc.common.save-as-draft' | translate}}</label>
          </div>
        </div>

<!--        <div class="col-md-2 pt-2">-->
<!--          <div class="form-group">-->
<!--            <input-->
<!--              id="print"-->
<!--              class="form-check-input"-->
<!--              type="radio"-->
<!--              formControlName="scheduleState"-->
<!--              value="PRINT"-->
<!--              (change)="changeSchedule($event)">-->
<!--            <label class="form-check-label mc-invoice-schedule-select" for="print">{{'cc.common.print' | translate}}</label>-->
<!--          </div>-->
<!--        </div>-->

        <div class="col-md-2 pt-2">
          <div class="form-group">
            <input
              id="scheduleSendNow"
              class="form-check-input"
              type="radio"
              formControlName="scheduleState"
              value="SEND_NOW"
              (change)="changeSchedule($event)" >
            <label class="form-check-label mc-invoice-schedule-select" for="scheduleSendNow">{{'cc.common.send-now' | translate}}</label>
          </div>
        </div>

        <div class="col-md-4 pt-2" [ngClass]="selectedScheduleDelayed ? 'mc-selected-schedule' : ''">
          <div class="form-group">
            <input
              id="scheduleSendDelayed"
              class="form-check-input"
              type="radio"
              formControlName="scheduleState"
              value="SCHEDULED"
              (change)="changeSchedule($event)">
            <label class="form-check-label mc-invoice-schedule-select" for="scheduleSendDelayed">{{'cc.common.send-delayed' | translate}}</label>
          </div>

          <div class="row mx-0" [ngClass]="{'mc-show-send-delayed' : !selectedScheduleDelayed, 'mc-selected-schedule' : selectedScheduleDraft}">
            <div class="col-md-6 pl-0">
              <label class="form-check-label text-capitalize" for="sendDelayedDate">
                {{'cc.common.view.date' | translate}}
              </label>
              <input type="date"
                     id="sendDelayedDate"
                     class="form-control p-1 mc-dates-inputs"
                     formControlName="sendDatePicker"
                     autocomplete="off"
                     placeholder="dd-mm-yyyy"
                     outsideDays="hidden"
                     [minDate] = "minScheduleSendDate"
                     [maxDate] = "maxScheduleDate"
                     ngbDatepicker
                     #sendDate="ngbDatepicker"
                     (click)="sendDate.toggle()"
                     (ngModelChange)="fullDate()"
                     style="padding-right: 10px !important;">
            </div>
            <div class="col-md-6 pr-0">
              <label class="form-check-label text-capitalize" for="sendDelayedTime">
                {{'cc.jobs.edit.time' | translate}}
              </label>
              <input type="time"
                     class="form-control p-1"
                     formControlName="sendTimePicker"
                     id="sendDelayedTime"
                     (ngModelChange)="fullDate()">
            </div>
          </div>
        </div>
      </div>

      <div class="row mx-0 pt-3 pb-3 w-100 align-items-center" [ngClass]="{'mc-schedule-date' : !isScheduleSelected, 'mc-selected-schedule' : selectedSchedule}">
        <div class="col-md-3">
          <div class="col-md-12 d-flex flex-direction-row align-items-center" [ngClass]="{'mc-hide' : hideSendDateField, 'mc-fields-invoice-status-scheduled' : isInvoiceSchedule}">
            {{'cc.invoices.view.send-date' | translate}}:
            <span class="mc-date pl-1">{{scheduleTodaySendDate}}</span>
          </div>
        </div>

<!--        <div class="col-md-4 d-flex flex-direction-row align-items-center">-->
<!--          <label class="form-check-label text-capitalize mr-3" for="changeDueDate">-->
<!--            {{'cc.invoice.change-due-date' | translate}}:-->
<!--          </label>-->
<!--          <input type="date"-->
<!--                 id="changeDueDate"-->
<!--                 class="form-control mc-dates-inputs p-1 mc-dates-due-date"-->
<!--                 formControlName="changeDueDateDate"-->
<!--                 autocomplete="off"-->
<!--                 placeholder="33-2-45"-->
<!--                 outsideDays="hidden"-->
<!--                 [minDate] = "minScheduleDueDate"-->
<!--                 [maxDate] = "maxScheduleDate"-->
<!--                 ngbDatepicker-->
<!--                 #dueDate="ngbDatepicker"-->
<!--                 (click)="dueDate.toggle()"-->
<!--                 (ngModelChange)="changeDueDate()"-->
<!--                 style="padding-right: 10px !important;">-->
<!--        </div>-->
      </div>
    </div>

    <div class="col-md-12 mt-5">
      <div class="row">
        <div class="col-md-6">
          <h5 class="mb-0">{{'cc.common.selected-products' | translate}}</h5>
        </div>
        <div class="col-md-6 d-flex justify-content-end" *ngIf="servicePeriodFrom === servicePeriodTo">
          <h5 class="mc-schedule-title mr-3 mb-0">{{'cc.invoice.service-day' | translate}}:</h5>
          <span class="mc-highlight-grey">{{servicePeriodFrom}}</span>
        </div>
        <div class="col-md-6 d-flex justify-content-end" *ngIf="servicePeriodFrom !== servicePeriodTo">
          <h5 class="mc-schedule-title mr-3 mb-0">{{'cc.invoice.service-period' | translate}}:</h5>
          <span class="mc-highlight-grey">{{servicePeriodFrom}} - {{servicePeriodTo}}</span>
        </div>
      </div>

      <mc-table [tableContent]="tableContent">
      </mc-table>

<!--      <mc-bank-account-select-alternate-->
<!--        [orderId]="orderId"-->
<!--        (sendBankAccount)="bankAccount = $event"-->
<!--        (sendErrorMessage)="onReceiveErrorFromChild($event)">-->
<!--      </mc-bank-account-select-alternate>-->
    </div>

    <div class="row">
      <div class="col-md-8 offset-md-4 d-flex justify-content-between mc-user-summary-action-btn mc-invoice-summary-action-btn">
        <div class="pt-5 px-0 d-flex align-items-center justify-content-start">
<!--          <a class="mc-invoice-preview-btn" [href]="invoicePreviewPdf" target="_blank">-->
          <a class="mc-invoice-preview-btn" href="javascript:void(0)" [class.disabled]="isDisablePreview" (click)="invoicePreview()">

            {{ 'cc.common.view.invoice-preview' | translate }}
            <i class="far fa-file-pdf pl-1"></i>
          </a>
        </div>

        <div class="pt-5 px-0 mc-user-summary-create-btn d-flex align-items-center justify-content-center" *ngIf="!isEditArea">
          <button id="userSummaryCreateBtn" class="btn btn-primary mc-next-save-btn mc-action-btns" type="button" [disabled]="isDisableCreate" (click)="createInvoice(1)">
            {{'cc.common.view.create' | translate}}
          </button>
        </div>

        <div class="pt-5 px-0 mc-user-summary-createAdd-btn d-flex align-items-center justify-content-end" *ngIf="isEditArea">
          <button id="updateInvoice" class="btn btn-primary mc-next-save-btn mc-action-btns" type="button" [disabled]="isDisableUpdate" (click)="updateInvoice()">
            {{'cc.invoice.update-invoice' | translate}}
          </button>
        </div>

        <div class="pt-5 px-0 mc-user-summary-createAdd-btn d-flex align-items-center justify-content-end">
          <button id="cancelUpdateInvoice" class="btn mc-cancel-btn" type="button" (click)="goToPrevious()">
            {{'cc.common.edit.cancel' | translate}}
          </button>
        </div>
      </div>
    </div>
  </form>
</section>

<!--Loader-->
<lib-mc-loader [showLoader]="showLoader"></lib-mc-loader>

