/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {EbConsumerBlacklist} from '../models/eb-consumer-blacklist.model';
import {EbConsumerBlacklistService} from '../services/eb-consumer-blacklist.service';
import {IEbConsumerBlacklist} from '../_generated/eb-consumer-blacklist.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class EbConsumerBlacklistGENERATED extends BaseObject {


  public apiService: EbConsumerBlacklistService;
  public _rawJson: IEbConsumerBlacklist;
    id: number = 0;
  addedDate: number;
  addedReason = '';
  addedUserId: number;
  birthday: number;
  city = '';
  email = '';
  extTransactionId = '';
  firstName = '';
  iban = '';
  idCsrConsumer: number;
  idMcEntity: number;
  idTransaction: number;
  lastName = '';
  postCode = '';
  removedDate: number;
  removedReason = '';
  removedUserId: number;
  street = '';
  addedByUser: string;  isRemovedFromBlacklist: boolean;  removedByUser: string;

  public properties: string[] = ['id', 'addedDate', 'addedReason', 'addedUserId', 'birthday', 'city', 'email', 'extTransactionId', 'firstName', 'iban', 'idCsrConsumer', 'idMcEntity', 'idTransaction', 'lastName', 'postCode', 'removedDate', 'removedReason', 'removedUserId', 'street', 'addedByUser', 'isRemovedFromBlacklist', 'removedByUser'];
  public propertiesRegular: string[] = ['id', 'addedDate', 'addedReason', 'addedUserId', 'birthday', 'city', 'email', 'extTransactionId', 'firstName', 'iban', 'idCsrConsumer', 'idMcEntity', 'idTransaction', 'lastName', 'postCode', 'removedDate', 'removedReason', 'removedUserId', 'street', 'addedByUser', 'isRemovedFromBlacklist', 'removedByUser'];
  public propertiesSpecial: string[] = [];


  // ---------------------------------------------------------------------
public static createNewInstance(): EbConsumerBlacklist {
    console.error('MTCN-ERROR: Not Implemented: EbConsumerBlacklist::createNewInstance(). Add this method to final class and return new EbConsumerBlacklist();');
    throw new Error('Cannot EbConsumerBlacklist::createNewInstance(). Add this method to final class and return new EbConsumerBlacklist();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IEbConsumerBlacklist): EbConsumerBlacklist {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(EbConsumerBlacklistService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE EbConsumerBlacklist AABB()');

  }

  // ---------------------------------------------------------------------


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --



  // ---------------------------------------------------------------------
  public loadActiveByIdCsrConsumer( idCsrConsumer: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.getActiveByIdCsrConsumer(idCsrConsumer)
      .subscribe(responseJson => {
        this.populateFromJson(responseJson);
         this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.processApiError(errorResponse, onErrorCallback);
      });
  }

// ---------------------------------------------------------------------
public callAddConsumersToBlacklist( consumerIds: any, reason: string, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.addConsumersToBlacklist(consumerIds,reason).subscribe(responseJson => {
    this.setApiSuccess();
    this.processApiSuccess(responseJson, onSuccessCallback);
    },
  errorResponse => {
    this.setApiError();
    this.processApiError(errorResponse, onErrorCallback);
  });
}

// ---------------------------------------------------------------------
public callRemoveConsumersFromBlacklist( consumerIds: any, reason: string, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.removeConsumersFromBlacklist(consumerIds,reason).subscribe(responseJson => {
    this.setApiSuccess();
    this.processApiSuccess(responseJson, onSuccessCallback);
    },
  errorResponse => {
    this.setApiError();
    this.processApiError(errorResponse, onErrorCallback);
  });
}

}
