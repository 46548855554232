/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {EbConsumerBlacklist} from '../models/eb-consumer-blacklist.model';
import {EbConsumerBlacklistList} from '../models/eb-consumer-blacklist-list.model';
import {EbConsumerBlacklistFilter} from '../models/eb-consumer-blacklist-filter.model';
import {EbConsumerBlacklistService} from '../services/eb-consumer-blacklist.service';
import {IEbConsumerBlacklist} from '../_generated/eb-consumer-blacklist.interface';
import {McGod} from "../extra/mc-god.service";

export class EbConsumerBlacklistListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'addedDate', 'addedReason', 'addedUserId', 'birthday', 'city', 'email', 'extTransactionId', 'firstName', 'iban', 'idCsrConsumer', 'idMcEntity', 'idTransaction', 'lastName', 'postCode', 'removedDate', 'removedReason', 'removedUserId', 'street', 'addedByUser', 'isRemovedFromBlacklist', 'removedByUser'];
  public items: EbConsumerBlacklist[] = [];
  public apiService: EbConsumerBlacklistService;
 // protected tempObjList: EbConsumerBlacklistList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): EbConsumerBlacklistList {
    console.error('MTCN-ERROR: Not Implemented: EbConsumerBlacklistList::createNewInstance(). Add this method to final class and return new EbConsumerBlacklistList();');
    throw new Error('Cannot EbConsumerBlacklistList::createNewInstance(). Add this method to final class and return new EbConsumerBlacklistList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IEbConsumerBlacklist[]): EbConsumerBlacklistList {
    const  listObj: EbConsumerBlacklistList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: EbConsumerBlacklist = EbConsumerBlacklist.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): EbConsumerBlacklistList {
    const  listObj: EbConsumerBlacklistList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(EbConsumerBlacklistService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IEbConsumerBlacklist[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: EbConsumerBlacklist = EbConsumerBlacklist.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aEbConsumerBlacklistFilter: EbConsumerBlacklistFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aEbConsumerBlacklistFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): EbConsumerBlacklist {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new EbConsumerBlacklist();
    }
  }

  public replaceItem(newItem: EbConsumerBlacklist) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --

// ---------------------------------------------------------------------
public callAddConsumersToBlacklist( consumerIds: any, reason: string, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.addConsumersToBlacklist(consumerIds,reason).subscribe(
    responseJson => {
      this.setApiSuccess();
      this.processApiSuccess(responseJson, onSuccessCallback);
    },
    errorResponse => {
      this.setApiError();
      this.processApiError(errorResponse, onErrorCallback);
    });
}

// ---------------------------------------------------------------------
public callRemoveConsumersFromBlacklist( consumerIds: any, reason: string, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.removeConsumersFromBlacklist(consumerIds,reason).subscribe(
    responseJson => {
      this.setApiSuccess();
      this.processApiSuccess(responseJson, onSuccessCallback);
    },
    errorResponse => {
      this.setApiError();
      this.processApiError(errorResponse, onErrorCallback);
    });
}

}

